.post-close-cross-single {
    top: 17%;
    left: 2%;
    position: fixed;
    font-size: 1.4rem;
    z-index: 10;
    height: 38px;
    width: 38px;
    cursor: pointer !important;
    border-radius: 50%;
    padding: 6px;
    border: none !important;
    background-color: #6D6D6D;
}

.post-close-cross :hover {

    cursor: pointer !important;
    border: 5px solid rgb(23, 33, 175);
}

.modal-video {
    display: block;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    margin: 0 auto;
    box-shadow: 3px 5px 7px rgb(0 0 0 / 50%);
}


/* .overlay img {
    position: absolute;
    width: 596px;
    height: 720px;
    left: 126px;
    top: 0px;

} */

.popup-left-box {
    background-color: #333;
    height: 92.3vh;
}

.popup-media {
    object-fit: contain;
    /* height: 100vh !important; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* 
.myoverlay{
    display: fixed;
   z-index: 11;
   width: 100%;
} */


.myoverlaysingle {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.941);
    display: flex;
    /* align-items: center; */
    z-index: 11;
    margin-top: 30px;
    /* overflow-y: auto; */
}

/* .overlay-arrows_right {
    display: flex;
    background-color: #6d6d6d;
    justify-content: space-between;
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;
    top: 50%;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    z-index: 999;
} */

/* .overlay-arrows_right {
    left: 53%;
    top: 52%;
    bottom: 50%;
    position: fixed;

} */


@media screen and (max-width: 992px) {
    .singleModal {
        overflow-y: auto !important
    }

    .post-modal-col1 {
        height: 91vh !important;
    }

    .post-modal-col2 {
        height: 100vh !important;
    }
}