#profile-cover-container div.reactEasyCrop_Container img.reactEasyCrop_Image.reactEasyCrop_Contain {
    width: 100%;
    max-height: none;
    min-height: 100%;
}

#profile-cover-container div.reactEasyCrop_Container .reactEasyCrop_CropArea {
    height: 100% !important;
    width: 100% !important;
}

.cover-slider {
    background-color: rgb(205, 205, 205);
    padding: .3rem .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    height: auto;
    margin-bottom: 1.2rem;

}

.slider-icons {
    font-size: 1.8rem;
    margin: 0 .34rem;
}

.cover-slider input {
    cursor: pointer;
}


@media(max-width: 768px) {
    .cover-slider {
        width: auto !important
    }
}