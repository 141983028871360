/* .wrapper-bg {
    font-family: 'Segoe UI' !important;
} */

.single-blog {
    background-color: white;
    padding: 0 1.3rem;
    padding-top: .5rem !important;
}

.blog-img {
    height: auto !important;
    max-height: auto;
}

.blog-img img {
    max-height: 100% !important;
    background-size: cover !important;
    object-fit: cover;
}

.blog-text p {
    color: black !important;
}

.blog-footer .user-info {
    margin-left: .9rem;
}

/* .sharePreviewBox img {
    width: 100% !important;
    height: auto !important;
} */

.blog-footer .user-info p {
    font-size: .88rem;
    margin: 0;
    height: 15px;
}

.blog-footer .user-info span {
    font-size: .55rem;
    height: 13px;
}

.blog-heading {
    font-weight: 400;
}


@media (max-width: 900px) {
    .main.blog-main {
        width: 95% !important;
    }

    .blog-react-bar {
        flex-direction: column;
        align-items: flex-start !important;

    }

    .blog-react-bar div:first-child {
        width: 100% !important;
    }

    .blog-img {
        height: auto;
    }

    .blog-reactions-container {
        width: 100% !important;
    }
}

@media (max-width: 450px) {
    .blog-heading {
        font-size: 1.44rem !important;
    }
}