.loader-wheel {
    font-size: 26px;
    position: relative;
    height: 1em;
    width: 1em;
    padding-left: 0.45em;
    overflow: hidden;
    margin: 0 auto;
    animation: loader-wheel-rotate 1s steps(12) infinite;
}

.loader-wheel i {
    display: block;
    position: absolute;
    height: 0.3em;
    width: 0.1em;
    border-radius: 0.05em;
    background: #333;
    opacity: 0.8;
    transform: rotate(-30deg);
    transform-origin: center 0.5em;
}

@keyframes loader-wheel-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}