.file-preview-box {
    position: absolute;
    /* top: -9rem; */
    z-index: 100;
    padding: 0.5rem;
    border: 1px solid rgb(200, 200, 200);
    background-color: white;
    max-width: calc(100% - 40px);
}

.file-preview-box .svg-container {
    position: absolute;
    right: -1.5rem;
    top: -.5rem;
    border: 2px solid white;
    height: 1.9rem;
    width: 1.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: #333;
    border-radius: 50%;
    background-color: rgb(188, 188, 188);
    z-index: 111;
}


.file-preview-box .preview-container {
    height: max-content;
    /* width: max-content; */
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.file-preview-box .preview-container img {
    border-radius: .33rem;
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.file-preview-box .preview-container video {
    border-radius: .33rem;
    height: 100px;
    width: 100px;
    object-fit: cover;
}

.file-preview-box .preview-container a {
    word-break: break-all;
    width: 100%;
}

.msg-file-container>* {
    height: 8rem;
    width: 8rem;
    border-radius: 8px;
    object-fit: cover;
}

.chat-file-icons {
    width: 5rem !important;
    height: 6rem !important;
    object-fit: cover;
}