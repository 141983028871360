.icon-wrapper.header-icons a:hover {
    background-color: #ebe8e8;
}

.friend-notifications .notification-item a:hover {
	background-color: transparent;
}

.friend-notifications .active {
	background-color: var(--lightblue);
}

.bg-transparent .rbt-menu{
	width: 100% !important;
  }
  .dropdown-menu .rbt-menu{
	width: 100%;
  }