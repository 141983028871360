.lecture-modal-dialog {
    min-width: 550px !important;
}

@media (max-width: 900px) {
    .mainmenu-page-search {
        padding: auto .22rem !important;
    }

    #sortcategory {
        width: 2rem !important;
    }

    #sortby {
        width: 7rem !important;
    }

    .instructor-image img {
        height: 80px !important;
        width: 80px !important;
    }
}

@media (max-width: 588px) {
    .save-close-btns {
        margin-top: .77rem;
    }

    .section-title {
        font-size: 15px !important;
    }

    .section-order {
        display: none !important;
    }

    .lecture-modal-dialog {
        min-width: 98% !important;
    }

    .section-imgs {
        height: 18px !important;
        width: 18px !important;
    }

    .upload-lecture-heading {
        font-size: 16px !important;
    }

    .drag {
        font-size: 14px !important;
    }

    .save-close-btns a,
    .save-close-btns button {
        font-size: .77rem !important;
        padding: .33rem .44rem !important
    }
}