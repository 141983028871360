@media (max-width: 576px) {
    .score-item {
        width: 80% !important;
    }
}

@media (max-width: 450px) {
    .score-item {
        width: 95% !important;
        margin: auto auto !important;
    }
}