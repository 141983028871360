.form-container form {
    /* height: 300px !important; */
}

.post-ssc span:last-child {
    margin-right: 0 !important;
}

.blog-post-container {
    background-color: white;
    padding: 2rem 4rem;
    position: relative;
    display: block;
    min-height: auto;
}


.blog-image-wrapper {
    height: 405px;
    width: 100%;
}


.blog-image-container {
    border: 2px dashed rgb(185, 184, 184);
    height: 100%;
    width: 100%;
}


.image-preview-blog {
    position: relative;
    width: 100%;
    height: 100%;
}

.image-preview-blog img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.blog-post-container .editor-container .ck-editor__editable {
    height: 16rem;
}


.blog-image-btn {
    cursor: pointer;
}


label.blog-label {
    color: rgb(68, 68, 68);
}

input#headline {
    border: 1px solid rgb(180, 180, 180) !important;
    box-shadow: none !important;
    font-size: 1.2rem;
}

input#headline::placeholder {
    font-size: 1rem;
    color: rgb(173, 186, 182) !important;
}

.image-preview-blog {
    z-index: 2;
    position: relative;
}

.image-preview-blog svg {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    right: 20px;
    top: 20px;
    border-radius: 50%;
}

.blog-add-image-btn {
    color: rgb(92, 135, 209);
    background-color: rgb(218, 238, 255);
    border-radius: 5px;
    font-weight: 500;
}


.form-container button {
    width: 100px !important;
}

.form-container .publish-btn {
    border-radius: 20px;
    width: 8rem !important;
}

.blog-pop-up {
    max-height: 20rem !important;
    overflow-y: auto;
}

.blog-caption {
    margin: 1rem 0;
    box-sizing: border-box !important;
}

.blog-caption input {
    font-weight: 500;
}

.blog-caption input:hover,
.blog-caption input:focus,
.blog-caption input:active {
    border: 2px solid rgb(154, 154, 154) !important;
    box-sizing: border-box !important;
    border-radius: 4px;


}

.blog-publish-btn {
    margin-top: 1rem !important;
}








@media (max-width: 930px) {
    .blog-post-container {
        padding: 2rem .88rem;
    }


}


@media (max-width: 450px) {
    .blog-image-wrapper {
        height: 300px;
    }


}