.container {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    inset: 0;
    z-index: 1500;
    /* backdrop-filter: blur(2px); */
}

.dialog-container {
    padding: 30px 40px 30px 40px;
    border-radius: 30px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-width: 500px;
    transform: translate(0, -60px);
}

.dialog-header {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 1.2;
    color: #151515;
    margin-bottom: 10px;
}

.dialog-body {
    font-size: 22px;
    font-weight: 400;
    color: black;
    margin: 0;
}

.dialog-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    align-items: center;
    justify-self: flex-end;
    margin-top: 25px;
}

.dialog-button {
    padding: 10px 25px;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1.1;
    background-color: var(--gray);
    color: white;
    border: none;
    border-radius: 30px;
}

.dialog-button:hover {
    background-color: var(--darkgray);
}

.alter {
    background-color: var(--blue);
}

.alter:hover {
    background-color: #00279d;
}

.danger {
    background-color: #ff1818;
    color: white;
}

.danger:hover {
    background-color: #c81515;
}