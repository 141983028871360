.feed-gallery .feed-gal-item {
    margin: 1px !important;

}

.post-ssc span:last-child {
    margin-right: 0 !important;
}

.feed-gallery .feed-gal-item img {
    border-radius: 4px;
}

.feed-gallery .feed-third2 {
    grid-column: 1/-1 !important;
    display: block !important;
}

.feed-gallery .feed-third2 img {
    width: 100% !important;
}


/* .videoTwo {
    height: auto;
    width: 100%;
} */

.user-profile-image {
    height: 40px !important;
    width: 40px !important;
    aspect-ratio: 40/40 !important;
}



.react-input-emoji--container .react-input-emoji--wrapper {
    z-index: 1 !important;
}






.Like {
    color: rgb(12, 102, 228) !important
}

.Love {
    color: rgb(235, 13, 113) !important
}

.Haha {
    color: rgb(252, 178, 51) !important
}

.Care {
    color: rgb(252, 178, 51) !important
}

.Sad {
    color: rgb(252, 178, 51) !important
}

.Wow {
    color: rgb(252, 178, 51) !important
}

.Angry {
    color: rgb(219, 113, 0) !important
}

.comment-container {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
}

.comment-container span {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    display: inline-block;

}

.comment-container img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 5px;
}


.comment-box {
    background-color: rgb(249, 249, 249);
    padding: .3rem .8rem;
    border-radius: 12px;
    color: rgb(105, 105, 105);
    font-size: 12px;
    /* /* display: flex; */
    flex-direction: column;
    line-height: 18px;
}

.comment-box p {
    padding: 0;
    margin: 0;
}

.comment-user {
    color: rgb(45, 43, 43);
    font-weight: 500;
    height: 14px;

}

.user-comment {
    color: rgb(88, 88, 88)
}

.react-reply-btns {
    color: rgb(12, 102, 228)
}

.comment-box .profileTagline {
    font-size: 9px;
}

.view-more-btn {
    font-size: 12px;
    margin-top: 10px !important;
    display: inline-block;

}

.view-more-btn:hover {
    text-decoration: underline;
}


.comment-like-reply-box span {
    font-size: 10px;
    margin-right: 8px;
    cursor: pointer;
    margin-top: 4px;
}

.feed-gal-item {
    display: grid;
    gap: 10px;
}

.feed-gal-item img {
    height: 260px !important;
    width: 260px !important;
    background-size: 100%;
    object-fit: cover;
}

.sharePreviewBox .feed-gal-item img {
    height: 200px !important;
    background-size: 100%;
    object-fit: cover;
}

.show {
    display: block;
}

.hide {
    display: none;
}

.comment-reaction {
    display: flex;
    align-items: center;
    justify-content: center;
}

.comment-reaction img {
    width: 13px;
}

.replies {
    margin-left: 50px;
}


.totalReplies {
    font-weight: bold;
    margin-left: .42rem !important;
}

.comment-time {
    font-size: 9px !important;
}

.sharePreviewBox {
    /* background-color: #EEF3F8; */
    border: 1px solid #ccc;
    padding: 5px;
}

video::-moz-play-progress {
    display: none !important;
}

video::-moz-controls {
    display: flex !important;
}

/* modal */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(27, 27, 27, 0.941);
    display: flex;
    align-items: center;
    z-index: 11;
    margin-top: 30px;
}

.overlay img {
    display: block;
    max-width: 60%;
    max-height: 80%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}

.overlay>span {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    color: #ffffff;
    z-index: 999;
    cursor: pointer;
}

.overlay-arrows_left {
    display: flex;
    background-color: #cbcbcb;
    justify-content: space-between;
    opacity: .5;
    position: absolute;
    top: 48%;
    width: 40px;
    left: 1.5%;
    height: 40px;
    color: black;
    user-select: none;
    z-index: 999;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.overlay-arrows_left svg {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: black;
    cursor: pointer;
}



.overlay-arrows_right {
    display: flex;
    background-color: #cbcbcb;
    justify-content: space-between;
    position: absolute;
    right: 1.7%;
    width: 40px;
    height: 40px;
    top: 48%;
    user-select: none;
    opacity: .5;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    align-items: center;
    z-index: 999;
}

.overlay-arrows_right svg {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    opacity: 1;
    color: black;
    cursor: pointer;
}

/* .comment-form>div:nth-of-type(1) {
    background: rgb(233, 233, 233);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
} */

.comment-form div button.react-input-emoji--button svg {
    height: .6rem;
    fill: #bbb;
}

.comment-form .comment-cam-label {
    font-size: .5rem;
    cursor: pointer;
}

.comment-form .comment-cam-label img {
    height: 100%;
    width: 100%;
}

#shareModal svg#Layer_1 {
    position: absolute;
    top: 30%;
    left: 45%;
}


.reactions-box {
    height: 300px;
    overflow-y: auto;
}

.reaction-container {
    position: relative;
}

.reaction-container .user-post-reaction {
    position: absolute;
    width: 18px;
    height: 18px;
    bottom: 0px;
    left: 20px;

}

#comment-media {
    width: 100%;
    border-radius: 2px;
}

.image-preview {
    height: 10rem !important;
    width: 10rem;
    position: relative;
    margin-left: 4rem;
}

.image-preview img {
    max-height: 100% !important;
}


.image-preview svg {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    right: 3px;
    top: 7px;
    border-radius: 50%;
}

.shared-info {
    border: 1px solid grey;
    border-radius: 5px;
    ;
}

.shared-user-container {
    max-height: 26px;
    margin-top: 5px;
}

.shared-user-container span.feed-user-image img#shared-user-img {
    min-width: 30px !important;
    max-height: 30px !important;
    border-radius: 50% !important;
    object-fit: cover !important;
}

.shared-user-name span:first-child {
    font-size: .66rem;
    font-weight: 500;
    height: 12px !important;
}

.shared-user-name span:last-child {
    font-size: .55rem;
    font-weight: 500;
    /* height: 12px !important; */
}

.withVideo,
.third0,
.third1 {
    height: 260px !important;
    object-fit: cover;
    vertical-align: middle;
    border-radius: 5px;
}

.feed-add-comment {
    border: 1px solid rgb(194, 194, 194);
    /* border: 1px solid #dee2e6; */
}


/* .video-container {
    position: relative;
    width: 500px;
    height: 375px;
}

.video-container video {
    width: 100%;
    height: 100%;
}

.controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: space-between;
    padding: 10px;
    color: white;
}

.controls button,
.controls input[type="range"] {
    cursor: pointer;
    outline: none;
} */




@media (max-width: 524px) {
    .feed-gal-item img .feed-gal-item video {
        height: 5rem !important;
    }

    .blog-img {
        height: auto !important;
    }

    .post-time {
        font-size: 8px !important;
    }

    .feed-gal-item img {
        height: 170px !important;
    }

    .feed-gal-item video {
        width: 100% !important;
    }
}