.ck.ck-balloon-panel.ck-balloon-panel_arrow_nw.ck-balloon-panel_visible.ck-balloon-panel_with-arrow,
.ck.ck-link-form.ck-responsive-form,
.ck-balloon-rotator,
.ck-balloon-rotator__content {
    z-index: 1096 !important;
}

:root {
    --ck-z-default: 10555 !important;
    --ck-z-modal: calc(var(--ck-z-default) + 10555) !important;

}


.custom-number-input::-webkit-outer-spin-button,
.custom-number-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.custom-number-input[type="number"] {
    -moz-appearance: textfield;
}