.teacher-container {
    cursor: pointer;
}


.teacher-box:hover {
    background-color: #eaeaea;
    border-radius: 9px;
}

.teacher-box {
    display: flex;
    padding: .4rem .77rem;
}

.teacher-box img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-right: 9px;
}