.post-share-preview-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2px;
    grid-template-rows: 1fr;
}

.post-image {
    width: 1rem;
}

.epr-preview.FlexRow,
.epr-skin-tones.epr-horizontal {
    display: none !important;
}

aside.EmojiPicke1rReact.epr-main {
    left: 6.5rem !important;
}


.post-share-container video {
    border-radius: 4px !important;
    max-width: 100% !important;
}

.post-share-container image {
    margin-top: 0;
}


.post-share-container .prviewBox {
    height: auto !important;
    /* max-height: 300px !important; */
}

.post-share-preview-container img,
.post-share-preview-container-three img {
    border-radius: 4px;
    height: 100%;
    object-fit: cover;
}

.post-share-preview-container-three,
.single-post-images {
    vertical-align: top !important;
}

.video-third {
    vertical-align: top !important;
    display: inline-block;
}

.video-third video {
    object-fit: cover !important;
}

.post-share-preview-container-three .third0 {
    width: 100%;
    max-height: 15rem !important;
}

.third {
    width: 49.5%;
    display: inline-block;
    height: auto;
    margin: 1px;
    height: 15rem !important;
}






.third0 {
    height: 15rem !important;
}

.third0 img {
    height: 100% !important;
    width: 100% !important;
}

.third img {
    height: 100% !important;
    padding: 2px;
}

.single-post-images {
    position: relative;
}

.single-post-images div {
    background-color: rgb(207, 205, 205);
    border-radius: 50%;
    position: absolute;
    left: 2%;
    top: 2.7%;
    height: 1.4rem;
    width: 1.4rem;
    display: flex;
    justify-content: center !important;
    align-items: center;
}

.single-post-images div svg {
    height: auto !important;
    width: auto !important;
    fill: #333;
}

.link-preview-container {
    border: 1px solid rgb(189, 188, 188);
    position: relative;
    border-radius: 5px;
    /* max-height: 15rem; */
    overflow-y: auto;
    padding: .77rem
}

.link-preview-container img {
    width: 100%;
    max-width: 100%;
}

.preview-cross {}

#link-preview-cross {
    position: absolute;
    top: 2%;
    right: 1%;
}