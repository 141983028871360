.blog-container {
    background-color: #EEF3F8;
    /* padding: .5rem 0; */
    border-radius: 10px;
}



.blog-container h6 {
    margin: .5rem .2rem .4rem .5rem;
    color: #333;
    padding: .5rem 0;
}


.blog-container .feed-image img {
    height: 260px !important;
    object-fit: cover;
}

.blog-description {
    margin-left: 10px;
    font-size: 13px;
    margin-bottom: 0;
}

.comment-form div button.react-input-emoji--button {
    margin-top: 0;
}

.comment-form div button.react-input-emoji--button svg {
    height: 1.16rem;
    fill: rgb(175, 175, 175);
}

.comment-form .comment-cam-label {
    font-size: .5rem;
    cursor: pointer;
}

.comment-form .comment-cam-label img {
    height: 100%;
    width: 100%;
}

.post-ssc span:last-child {
    margin-right: 0 !important;
}

.blog-description p {
    margin-bottom: .35rem;
}