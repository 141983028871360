.post-reactions-container {
    cursor: pointer;
    border: 1px solid rgb(210, 209, 209);
    display: inline-block;
    border-radius: 40px;
    padding: 7px;
    margin-top: 10px;
    position: absolute;
    z-index: 2;
    background-color: #fff;
}




.post-reactions-container.open {
    visibility: visible;
    display: inline-block;
    opacity: 1;
}

.post-reactions-container.close {
    visibility: hidden;
    display: none;
    opacity: 0;
}


.post-reactions-container span img {
    height: 25px;
    width: 25px;
    margin-left: 6px;
}

.post-reactions-container span img:hover {
    scale: 1.3;
    transition: all .2s ease;
}

.post-reactions-container:hover {
    visibility: visible;
    display: inline-block;
    opacity: 1;
}

.commentReaction {
    position: absolute;
    background-color: #fff;
    z-index: 2;
}