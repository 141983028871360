.msg-count {
    height: 1rem;
    width: 1rem;
    background: #0C64C5;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .77rem;
    padding: .68rem;

}