@media (max-width: 1000px) {
    .profile-cover-btn {
        margin-left: 0 !important;
        width: auto;
    }


}

@media (max-width: 768px) {
    .profile-cover-btn {
        margin-left: 0 !important;
        width: auto;
    }

    .profile-dp {
        margin-top: -130px !important;
    }
}

@media (max-width: 578px) {
    .profile-cover-btn {
        margin-left: 0 !important;
        width: auto;
        margin: auto !important;
    }

    .profile-dp {
        margin-top: -60px !important;
    }

    .banner {
        height: auto !important;
    }
}