.comment-form div button.react-input-emoji--button svg {
    height: 1.26rem;
    fill: #bbb;
}

.comment-form .comment-cam-label {
    font-size: .5rem;
    cursor: pointer;
}

.comment-form .comment-cam-label img {
    height: 100%;
    width: 100%;
}