.password-container {
    position: relative;
}

.password-tooltip {
    position: absolute;
    background-color: #f5f5f5;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #ccc;
    left: -50%;
    pointer-events: none;
    z-index: 123;
    top: -40%;
}

.password-tooltip::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -6%;
    transform: translateX(-50%);
    transform: rotate(90deg);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 10px solid #f5f5f5;
}


.password-tooltip p {
    margin: 0;
    font-size: .88rem;
}

.text-green {
    color: rgb(0, 184, 25) !important;
    font-weight: 500;
}

@media (max-width: 1070px) {
    .password-tooltip {
        left: -58%;
        top: -40%;
    }
}

@media (max-width: 960px) {
    .password-tooltip {
        left: -69%;
        top: -40%;
    }
}



@media (max-width: 860px) {
    .password-tooltip {
        left: -78%;
        top: -40%;
    }
}

@media (max-width: 768px) {
    .password-tooltip {
        left: 47%;
        top: -75%;
    }

    .password-tooltip::before {
        content: "";
        position: absolute;
        top: 70%;
        left: -6%;
        transform: translateY(-50%);
        transform: rotate(-90deg);
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 10px solid #999;
    }
}